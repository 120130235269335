<template>
  <v-app>
    <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile-nologo'">
      <Loader :visible="isLoading" />
      <v-container class="complete-container" v-if="!isLoading" fill-height>
        <v-row class="pa-0 ma-0" width="100%" justify="center">
          <v-img v-if="mobile" class="my-5" :src="require('@/assets/img/byline.svg')" max-width="200px" />
        </v-row>
        <v-card
          outlined
          max-width="750px"
          width="100%"
          :class="'complete-card mb-5 ' + (mobile ? '' : 'mt-10')"
          v-if="!waitingPlan && !showModal"
        >
          <grid>
            <template v-slot:alone>
              <v-row class="ma-0 pa-0">
                <v-col cols="8" class="pr-5">
                  <div class="text-tway-title">
                    <span class="text-capitalize"
                      >{{ getUmapiUser.firstName }},
                      {{ getCompany.type == "SPECIALIST" ? "tu perfil Especialista" : "tu perfil Cliente" }}</span
                    >
                  </div>
                  <div class="profile-content-box roboto">
                    {{
                      getCompany.type === "CLIENT"
                        ? "Este es el primer paso para acercarte a la Economía Digital o Industria 4.0. Perfila tu negocio y recibe información especializada sobre Transformación en tu industria."
                        : " Este es el primer paso para conectar con nuevos clientes y promocionar tus productos y servicios de Transformación Digital."
                    }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-row class="ma-0 pa-0" justify="center" align="center">
                    <v-img
                      :src="
                        require(getCompany.type === 'CLIENT'
                          ? '@/assets/img/perfilamiento.svg'
                          : '@/assets/img/perfilamiento-e.svg')
                      "
                      max-width="80%"
                      contain
                    />
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </grid>
        </v-card>
        <v-row class="pa-0 ma-0" style="width: 100%" justify="center">
          <v-card
            outlined
            max-width="750px"
            width="100%"
            :class="'complete-card mb-5 ' + (mobile ? '' : 'mb-10')"
            v-if="!waitingPlan && !showModal"
          >
            <SpecialistRegistration
              v-if="getCompany.type === 'SPECIALIST'"
              :company-data="getCompany"
              :user-data="user"
              :company-sizes="getCompanySizes"
              :company-sectors="getCompanySectors"
              :company-business-areas="getCompanyBusinessAreas"
              :company-workplace-senioritys="getCompanyWorkplaceSeniority"
              :company-categories="getCompanyCategories"
              :company-dimensions="getCompanyDimensions"
              @hide="hide"
              @planModal="planModal"
            />
            <ClientRegistration
              v-if="getCompany.type === 'CLIENT'"
              :user-data="user"
              :company-data="getCompany"
              :company-sizes="getCompanySizes"
              :company-sectors="getCompanySectors"
              :company-business-areas="getCompanyBusinessAreas"
              :company-workplace-senioritys="getCompanyWorkplaceSeniority"
              @hide="hide"
              @planModal="planModal"
            />
          </v-card>
        </v-row>
      </v-container>
      <v-dialog v-model="showModal" max-width="30em" persistent>
        <v-card class="pt-8 pb-8">
          <v-card-title class="d-block">
            <h1 class="text-h5 tway-header-1 text-center px-15" style="word-break: break-word">Información</h1>
          </v-card-title>
          <v-card-text class="text-center mt-7" v-if="planDone">
            <div class="px-md-10 px-0">
              <v-img :src="require(`@/assets/img/coming-soon-imo.svg`)" contain :max-height="130" />
              <div class="mt-5 text-center text-oboarding">
                Reinicia tu sesión para acceder al primer paso de tu transformación digital
              </div>
              <div class="mt-5 text-center text-oboarding">
                Al hacer clic en el botón “Aceptar”, te redireccionaremos a la vista de inicio de sesión donde ingresas
                tus credenciales. Aquí comienza tu transformación digital
              </div>
            </div>
          </v-card-text>
          <v-card-text class="text-center mt-7" v-else>
            <div class="px-md-10 px-0">
              <div class="mt-5 text-center text-oboarding">
                Estamos configurando la plataforma para que conozcas tus brechas digitales
              </div>
              <v-progress-circular indeterminate color="purple"></v-progress-circular>
              <div class="mt-5 text-center text-oboarding">Esto tomará unos segundos</div>
            </div>
            <div class="px-md-10 px-0" v-if="planDone">
              <div class="mt-5 text-center text-oboarding"></div>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              large
              rounded
              color="tway-violet"
              class="white--text roboto font-weight-bold"
              min-width="120"
              :disabled="!planDone"
              @click="
                () => {
                  logout();
                }
              "
            >
              aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>
<style lang="scss" scoped>
.text-tway-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
}
</style>
<script>
import Loader from "@/components/Loader";
import SpecialistRegistration from "@/components/profile-registration/specialist/SpecialistRegistration";
import ClientRegistration from "@/components/profile-registration/client/ClientRegistration";
import RowTwo from "@/components/grid/RowTwo.vue";
import planService from "@/services/planService";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SpecialistRegistration,
    ClientRegistration,
    grid: RowTwo,
    Loader,
  },
  data() {
    return {
      user: {},
      hideImg: false,
      isLoading: true,
      showModal: false,
      waitingPlan: false,
      planDone: false,
      count: 0,
    };
  },
  async created() {
    await this.$recaptchaLoaded();
    let token = [];
    for (let i = 0; i < 3; i++) {
      token[i] = await this.$recaptcha("registration");
    }
    const promises = [
      this.setUmapiUserData(),
      this.setCompanyProfile(),
      this.setSectors(this.getClientId),
      this.setSizes(this.getClientId),
      this.setBusinessAreas(token[0]),
      this.setWorkplaceSenioritys(token[1]),
    ];
    await Promise.all(promises)
      .then(async () => {
        const { metadata } = this.getUmapiUser;
        this.user = metadata;
        if (this.getCompany.type == "SPECIALIST") {
          await Promise.all([
            this.setCategories({
              clientId: this.getClientId,
              withSpecialistsAmount: false,
            }),
            this.setDimensions(this.getClientId),
          ]);
        }
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions("CompanyStore", [
      "setCompanyProfile",
      "setSizes",
      "setSectors",
      "setBusinessAreas",
      "setWorkplaceSenioritys",
      "setCategories",
      "setDimensions",
    ]),
    ...mapActions("UserStore", ["setUmapiUserData"]),
    ...mapActions("authentication", ["doLogout"]),

    hide(flag) {
      this.hideImg = flag;
    },
    planModal(flag) {
      this.waitingPlan = flag;
      this.showModal = flag;
    },
    updatePlanHandler() {
      setTimeout(async () => {
        const subscription = await planService.getSuscription();
        console.log(subscription);
        if (subscription.plan.name != "NOPLAN") {
          this.planDone = true;
          localStorage.removeItem("promo");
        } else {
          this.count++;
        }
      }, 5000);
    },
    logout() {
      this.doLogout()
        .then(() => this.$router.push({ name: "login" }))
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
  },
  watch: {
    waitingPlan() {
      this.updatePlanHandler();
    },
    count() {
      this.updatePlanHandler();
    },
  },
  computed: {
    ...mapGetters("CompanyStore", [
      "getCompany",
      "getCompanySizes",
      "getCompanySectors",
      "getCompanyBusinessAreas",
      "getCompanyWorkplaceSeniority",
      "getCompanyAgeRange",
      "getCompanyCategories",
      "getCompanyDimensions",
    ]),
    ...mapGetters("UserStore", ["getUmapiUser", "getClientId"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    desktop2() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-content-box {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
.small-checkbox {
  margin-top: 0px;
  padding-top: 0px;
}

.small-checkbox > div > div {
  align-items: start;
}
.complete-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
</style>
