<template>
  <v-stepper class="elevation-0" v-model="currentStep">
    <v-stepper-header>
      <v-row>
        <v-col cols="3">
          <v-stepper-step :complete="currentStep > 1" step="1" color="#9652DF"> Datos Personales </v-stepper-step>
        </v-col>
        <v-divider />
        <v-col cols="3">
          <v-stepper-step :complete="currentStep > 2" step="2" color="#9652DF"> Datos Básicos </v-stepper-step>
        </v-col>
        <v-divider />
        <v-col cols="3">
          <v-stepper-step :complete="currentStep > 3" step="3" color="#9652DF"> Otros Datos </v-stepper-step>
        </v-col>
        <v-divider />
        <v-col cols="3">
          <v-stepper-step step="4" color="#9652DF"> Confirmación </v-stepper-step>
        </v-col>
      </v-row>
    </v-stepper-header>
    <v-stepper-items class="tway-stepper">
      <!-- PASO 1 -->
      <v-stepper-content step="1">
        <v-form ref="formStep1" v-model="formStep1">
          <v-row class="mt-4">
            <v-col cols="12">
              <div class="stepper-item mb-2">
                <span
                  style="
                    font-family: Josefin Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 150%;
                    color: #333333;
                  "
                  class="mb-2"
                  >¿En qué área trabajas?</span
                >
                <v-autocomplete
                  :disabled="businessAreaSet"
                  v-model="user.businessArea"
                  label="Selecciona un área"
                  name="businessArea"
                  :items="companyBusinessAreas"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="code"
                  :rules="[required('¿En qué área trabajas?')]"
                  required
                  dense
                  outlined
                  color="#7319D5"
                />
              </div>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mb-5" justify="center" align="center">
            <div class="d-inline">
              <v-btn
                depressed
                rounded
                class="button-text tway-violet white--text roboto elevation-3"
                :disabled="!formStep1"
                @click="updateProfile(2)"
                id="profile-nextstep-2"
              >
                Siguiente
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-stepper-content>
      <!-- PASO 2 -->
      <v-stepper-content step="2">
        <v-form ref="formStep2" v-model="formStep2">
          <div class="stepper-item mb-5">
            <label>Razón Social de tu empresa</label>
            <v-text-field
              v-model="company.name"
              :rules="[required('Razón Social')]"
              :disabled="nameSet"
              class="roboto"
              placeholder="Escribe aquí la Razón Social"
            />
          </div>
          <div class="stepper-item mb-2">
            <label>¿Cuál es tu negocio principal?</label>
            <v-tooltip bottom class="tooltip">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="ml-auto icono"> mdi-information-outline </v-icon>
              </template>
              <span v-if="!newCompany" class="sii roboto">Dato entregado por el SII</span>
              <span v-if="newCompany" class="sii roboto"
                >Revisa la actividad económica de tu empresa para saber tu negocio principal.</span
              >
            </v-tooltip>
            <v-select
              v-model="company.industrialSector"
              :items="companySectors"
              item-text="name"
              :rules="sectorSet ? [] : [requiredObject('negocio principal')]"
              :disabled="sectorSet"
              return-object
              attach
              chips
              label="Selecciona un Sector"
              class="roboto"
            />
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-6">
            <label>Cuéntanos acerca de tu empresa</label>
            <v-textarea
              v-model="company.contributionValue"
              rows="3"
              no-resize
              placeholder="Breve descripción sobre tu producto o servicio que ofreces"
              hide-details
              class="roboto"
            />
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-5">
            <label>¿Desde qué año está activa tu empresa?</label>
            <v-tooltip bottom class="tooltip">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="icono"> mdi-information-outline </v-icon>
              </template>
              <span v-if="!newCompany" class="sii roboto">Dato entregado por el SII</span>
              <span v-if="newCompany" class="sii roboto"
                >Ingresa el año donde tu empresa comenzó actividades en el SII</span
              >
            </v-tooltip>
            <v-text-field
              v-model="company.trajectory"
              :disabled="yearSet"
              :rules="yearSet ? [] : [required('año de inicio'), validYear()]"
              maxlength="4"
              class="roboto"
              required
            />
          </div>

          <v-row class="pa-0 ma-0 mb-5" justify="center" align="center">
            <div class="d-inline">
              <v-btn
                depressed
                rounded
                class="button-text tway-violet white--text roboto elevation-3"
                @click="updateProfile(3)"
                id="profile-nextstep-2"
                :disabled="!formStep2"
              >
                Siguiente
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-stepper-content>
      <!-- PASO 3 -->
      <v-stepper-content step="3">
        <v-form ref="formStep3" v-model="formStep3">
          <div class="stepper-item mb-5">
            <label>Tamaño</label>
            <v-tooltip bottom class="tooltip">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="ml-auto icono"> mdi-information-outline </v-icon>
              </template>
              <span class="sii roboto">El tamaño está definido según el rango de facturación de tu empresa.</span>
            </v-tooltip>
            <v-select
              class="subtitle-2 roboto"
              v-model="company.size"
              :items="companySizes"
              :item-text="(companySizes) => `${companySizes.name} ${companySizes.salesUfRange}`"
              :rules="[requiredObject('Tamaño de la empresa')]"
              :disabled="sizeSet"
              placeholder="Selecciona un tamaño acorde a tu empresa"
              return-object
            />
          </div>
          <div class="separator mb-6" />

          <div class="stepper-item mb-5">
            <label>Cantidad de trabajadores</label>
            <v-text-field
              v-model="company.workers"
              class="roboto"
              :rules="[validWorkers()]"
              placeholder="Ingresa la cantidad total de trabajadores (sin puntos ni comas)"
              min="1"
            />
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-5">
            <label>Sitio Web</label>
            <v-text-field
              class="subtitle-2 roboto"
              v-model="company.web"
              placeholder="Ingresa el sitio web de tu empresa"
            />
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-5">
            <label>Perfil Linkedin</label>
            <v-text-field
              class="subtitle-2 roboto"
              v-model="company.linkedIn"
              placeholder="Ingresa el link del perfil de LinkedIn de tu empresa"
            />
          </div>
          <div class="separator mb-6" />

          <v-row class="pa-0 ma-0 mb-5" justify="center" align="center">
            <v-col class="pa-0 ma-0" cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
              <v-btn outlined color="deep-purple accent-4" class="button-text" rounded @click="currentStep = 1">
                Atrás
              </v-btn>
            </v-col>
            <v-col class="pa-0 ma-0" cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
              <v-btn
                depressed
                rounded
                class="button-text tway-violet white--text"
                :disabled="!formStep3"
                @click="updateProfile(4)"
              >
                Siguiente
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-stepper-content>
      <!-- PASO 4 -->
      <v-stepper-content step="4">
        <v-container>
          <v-row class="px-12">
            <v-col :cols="mobile ? '12' : '6'" v-if="businessAreaValue">
              <div class="stepper-item mb-5">
                <div class="h6">Área de trabajo</div>
                <div class="subtitle-2 roboto" style="color: #999999">
                  {{ businessAreaValue }}
                </div>
              </div>
            </v-col>
          </v-row>

          <div class="separator mb-6" v-if="company.industrialSector != undefined" />
          <v-row :class="mobile ? 'px-12' : ''">
            <v-col cols="12" v-if="company.industrialSector != undefined">
              <div class="stepper-item mb-5">
                <div class="h6">Negocio Principal</div>
                <div class="subtitle-2 roboto" style="color: #999999">
                  {{ company.industrialSector.name }}
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="separator mb-6" v-if="company.contributionValue" />
          <v-row :class="mobile ? 'px-12' : ''">
            <v-col cols="12" v-if="company.contributionValue">
              <div class="stepper-item mb-5">
                <div class="h6">Acerca de la Empresa</div>
                <div class="subtitle-2 roboto" style="color: #999999">
                  {{ company.contributionValue }}
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="separator mb-6" v-if="company.trajectory != null || company.size != null" />
          <v-row class="px-12">
            <v-col :cols="mobile ? '12' : '6'" v-if="company.trajectory">
              <div class="stepper-item mb-5">
                <div class="h6">Inicio Actividades</div>
                <div class="subtitle-2 roboto" style="color: #999999">
                  {{ company.trajectory }}
                </div>
              </div>
            </v-col>

            <v-col :cols="mobile ? '12' : '6'" v-if="company.size != null">
              <div class="stepper-item mb-5">
                <div class="h6">Tamaño</div>
                <div>
                  <a class="subtitle-2 roboto" style="color: #999999"
                    >{{ company.size.name }} {{ company.size.salesUfRange }}</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="separator mb-6" v-if="company.workers || company.web" />
          <v-row class="px-12">
            <v-col :cols="mobile ? '12' : '6'" v-if="company.workers">
              <div class="stepper-item mb-5">
                <div class="h6">Cantidad de Trabajadores</div>
                <div>
                  <a class="subtitle-2 roboto" style="color: #999999">{{ company.workers }}</a>
                </div>
              </div>
            </v-col>

            <v-col :cols="mobile ? '12' : '6'" v-if="company.web">
              <div class="stepper-item mb-5">
                <div class="h6">Sitio Web</div>
                <div>
                  <a
                    target="_blank"
                    class="subtitle-2 roboto"
                    style="color: #999999"
                    v-if="company.web !== ''"
                    :href="'http://' + company.web"
                    >{{ company.web }}</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="separator mb-6" v-if="company.linkedIn" />
          <v-row :class="mobile ? 'px-12' : ''">
            <v-col cols="12" v-if="company.linkedIn">
              <div class="stepper-item mb-5">
                <div class="h6">Perfil Linkedin</div>
                <div>
                  <a
                    class="subtitle-2 roboto"
                    style="color: #999999"
                    target="_blank"
                    v-if="company.linkedIn !== ''"
                    :href="'http://' + company.linkedIn"
                    >{{ company.linkedIn }}</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="separator mb-6" />

          <v-row v-if="siiAlert" class="pa-0 ma-0">
            <v-alert color="red" dense type="error">
              Debe completar los datos de Sector, Año de actividad, Tamaño y cantidad de trabajadores para completar el
              perfilamiento.
            </v-alert>
          </v-row>
          <v-row :class="mobile ? '' : 'ml-9'" class="pa-0 ma-0 mb-5" justify="center">
            <v-col :class="mobile ? 'pa-0 mr-6' : 'pa-0 ma-0'" cols="3" xs="2" sm="2" md="2" lg="2" xl="2">
              <v-btn
                outlined
                color="deep-purple accent-4"
                class="button-text"
                rounded
                @click="currentStep = 3"
                id="profile-nextstep-back-from-complete"
              >
                Atrás
              </v-btn>
            </v-col>
            <v-col class="pa-0 ma-0" cols="6" xs="6" sm="6" md="4" lg="4" xl="4">
              <v-btn
                depressed
                rounded
                class="button-text tway-violet white--text"
                @click="confirmProfile()"
                id="profile-nextstep-complete"
              >
                Confirmar mi perfil
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import companyService from "@/services/companyService";
import currentUserService from "@/services/currentUserService";
import ValidatorsService from "@/services/validatorsService";
export default {
  name: "ClientRegistration",
  props: {
    companyData: {
      type: Object,
      default: () => {},
      required: true,
    },
    companySizes: {
      type: Array,
      default: () => [],
      required: true,
    },
    companySectors: {
      type: Array,
      default: () => [],
      required: true,
    },

    companyBusinessAreas: {
      type: Array,
      default: () => [],
      required: true,
    },
    companyWorkplaceSenioritys: {
      type: Array,
      default: () => [],
      required: true,
    },
    userData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data() {
    return {
      businessAreaValue: null,
      formStep1: false,
      formStep2: false,
      formStep3: false,
      company: {},
      user: {
        businessArea: "",
        workplaceSeniority: "",
      },
      currentUser: {},
      currentStep: 1,
      nameSet: false,
      yearSet: false,
      sectorSet: false,
      sizeSet: false,
      newCompany: false,
      siiAlert: false,
      workplaceSenioritySet: false,
      businessAreaSet: false,
      waitingPromo: true,
      showModal: true,
    };
  },

  async created() {
    if (this.userData.businessArea) {
      this.businessAreaSet = true;
      this.user.businessArea = this.userData.businessArea;
    }
    if (this.userData.workplaceSeniority) {
      this.workplaceSenioritySet = true;
      this.user.workplaceSeniority = this.userData.workplaceSeniority;
    }
    this.company = this.companyData;
    if (this.company.trajectory) {
      this.yearSet = true;
    }
    if (this.company.size != null) {
      this.sizeSet = true;
    }
    if (this.company.industrialSector != null) {
      this.sectorSet = true;
    }
    if (this.company.name) {
      this.nameSet = true;
    }
    !this.company.checked ? (this.newCompany = true) : (this.newCompany = false);
  },

  methods: {
    confirmProfile() {
      const promo = localStorage.getItem("promo");
      if (promo) {
        this.$emit("planModal", true);
      } else {
        this.$router.push({ name: "home" });
      }
    },
    async updateProfile(step) {
      if (this.currentStep === 1) {
        await currentUserService.currentUserData().then((data) => {
          this.currentUser = data;
        });
        let data = this.currentUser;
        data.metadata = {
          ...data.metadata,
          ...this.user,
        };
        await currentUserService
          .updateUserData(data)
          .then(() => (this.currentStep = step))
          .catch((err) => this.$log.error(err));
      }
      if (this.currentStep === 2 || this.currentStep === 3) {
        this.currentStep = step;
      }
      if (this.currentStep === 4) {
        let data1 = {
          ...this.company,
          industrialSector: this.company.industrialSector.id,
        };
        companyService
          .updateCompanyProfile(data1)
          .then(() => (this.currentStep = step))
          .catch((err) => this.$log.error(err));
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    textRange() {
      return this.company.size.name + " " + this.company.size.salesUfRange;
    },
    validYear: () => ValidatorsService.validYear(),
    validWorkers: () => ValidatorsService.validWorkers(),
    required: (propertyType) => ValidatorsService.required(propertyType),
    requiredObject: (propertyType) => ValidatorsService.requiredObject(propertyType),
  },
  watch: {
    "user.businessArea"() {
      try {
        let value = this.companyBusinessAreas.find((element) => element.code == this.user.businessArea);
        this.businessAreaValue = value.name;
      } catch (error) {
        console.log(error);
      }
    },
    currentStep() {
      if (this.currentStep > 1) {
        this.$emit("hide", true);
      } else {
        this.$emit("hide", false);
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-stepper__header {
  box-shadow: none !important;
}
.separator {
  border: 1px solid #f1f1f1;
  width: 100%;
}
.icono {
  width: 38px;
  color: #4a148c;
  top: -2px;
  font-size: 18px !important;
}
.sii {
  color: white;
}
.tooltip {
  color: #4a148c;
}
</style>
