<template>
  <v-stepper class="elevation-0" v-model="currentStep">
    <v-stepper-header>
      <v-row>
        <v-col cols="3">
          <v-stepper-step :complete="currentStep > 1" step="1" color="#9652DF"> Datos Personales </v-stepper-step>
        </v-col>
        <v-divider />
        <v-col cols="3">
          <v-stepper-step :complete="currentStep > 2" step="2" color="#9652DF"> Datos Básicos </v-stepper-step>
        </v-col>
        <v-divider />
        <v-col cols="3">
          <v-stepper-step :complete="currentStep > 3" step="3" color="#9652DF"> Otros Datos </v-stepper-step>
        </v-col>
        <v-divider />
        <v-col cols="3">
          <v-stepper-step step="4" color="#9652DF"> Confirmación </v-stepper-step>
        </v-col>
      </v-row>
    </v-stepper-header>

    <v-stepper-items class="tway-stepper">
      <!-- PASO 1 -->
      <v-stepper-content step="1">
        <v-form ref="formStep1" v-model="formStep1">
          <v-row class="mt-4">
            <v-col cols="12">
              <div class="stepper-item mb-2">
                <span
                  style="
                    font-family: Josefin Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 150%;
                    color: #333333;
                  "
                  class="mb-2"
                  >¿En qué área trabajas?</span
                >
                <v-autocomplete
                  :disabled="businessAreaSet"
                  v-model="businessArea"
                  label="Selecciona un área"
                  name="businessArea"
                  :items="companyBusinessAreas"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="code"
                  :rules="[required('¿En qué área trabajas?')]"
                  required
                  dense
                  outlined
                  color="#7319D5"
                />
              </div>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mb-5" justify="center" align="center">
            <div class="d-inline">
              <v-btn
                depressed
                rounded
                class="button-text tway-violet white--text roboto elevation-3"
                :disabled="!formStep1"
                @click="updateProfile(2)"
                id="profile-nextstep-2"
              >
                Siguiente
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-stepper-content>
      <!-- PASO 2 -->
      <v-stepper-content step="2">
        <v-form ref="formStep2" v-model="formStep2">
          <div class="stepper-item mb-5">
            <label>Razón Social de tu empresa</label>
            <v-text-field
              v-model="company.name"
              :rules="nameSet ? [] : [required('Razón Social')]"
              :disabled="nameSet"
              class="roboto"
              placeholder="Escribe aquí la Razón Social"
            />
          </div>
          <div class="stepper-item mb-5">
            <v-row>
              <v-col>
                <label
                  >En relación a la Transformación Digital,<br />¿En cuál de estas dimensiones impacta tu
                  empresa?</label
                >
              </v-col>
            </v-row>
            <v-select
              :disabled="false"
              v-model="company.dimensions"
              :items="companyDimensions"
              item-text="name"
              attach
              chips
              label="Selecciona una o varias dimensiones"
              multiple
              return-object
              class="roboto condensed"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0 || index === 1 || index === 2">
                  <span v-if="item.name"
                    >{{ item.name.substring(0, 13) }} <span v-if="item.name.length > 13">..</span></span
                  >
                </v-chip>
                <span v-if="index === 3" class="grey--text caption"> (+{{ company.dimensions.length - 3 }}) </span>
              </template>
            </v-select>
            <span v-if="mobile" class="select-subtitle roboto"
              >{{ company.dimensions.length }} dimensiones seleccionadas</span
            >
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-2">
            <v-row>
              <v-col>
                <label>¿Qué servicios o productos ofreces para la Transformación Digital?</label>
              </v-col>
            </v-row>

            <choice
              :data="companyCategories"
              :data-selections="company.services"
              @array="company.services = $event"
              ref="choice"
              class="roboto"
            />
            <span v-if="mobile" class="select-subtitle roboto"
              >{{ company.services.length }} servicios seleccionados</span
            >
            <v-checkbox v-model="checkboxServices" color="success" :label="'No encontré el servicio que ofrezco.'" />
            <div v-if="checkboxServices">
              <v-row>
                <v-col>
                  <label>Escribe el servicio que ofreces</label>
                </v-col>
              </v-row>
              <v-text-field
                v-model="company.otherService"
                :disabled="otherServiceSet"
                :rules="[required('otros servicios')]"
                class="roboto"
              />
            </div>
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-2">
            <v-row>
              <v-col>
                <label>Sectores en los cuales has prestado servicios</label>
              </v-col>
            </v-row>
            <v-select
              :disabled="false"
              v-model="company.industrialSectors"
              :items="companySectors"
              item-text="name"
              attach
              chips
              label="Sectores"
              multiple
              class="roboto"
              return-object
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0 || index === 1 || index === 2">
                  <span v-if="item.name"
                    >{{ item.name.substring(0, 13) }} <span v-if="item.name.length > 13">..</span></span
                  >
                </v-chip>
                <span v-if="index === 3" class="grey--text caption">
                  (+{{ company.industrialSectors.length - 3 }})
                </span>
              </template>
            </v-select>
            <span v-if="mobile" class="select-subtitle roboto">{{ company.industrialSectors.length }} sectores</span>
            <v-checkbox
              v-if="!sectorSet"
              v-model="checkboxSectors"
              color="success"
              :label="'No he prestado servicios en estos sectores.'"
            />
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-5">
            <label>Año de inicio de actividades</label>
            <v-tooltip bottom class="tooltip">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="ml-auto icono"> mdi-information-outline </v-icon>
              </template>
              <span v-if="!newCompany" class="sii roboto">Dato entregado por el SII</span>
              <span v-if="newCompany" class="sii roboto"
                >Ingresa el año donde tu empresa comenzó actividades en el SII</span
              >
            </v-tooltip>
            <v-text-field
              v-model="company.trajectory"
              :disabled="yearSet"
              :rules="[validYear()]"
              maxlength="4"
              class="roboto"
            />
          </div>
          <div class="separator mb-6" />

          <v-row class="pa-0 ma-0 mb-5" justify="center" align="center">
            <div class="d-inline">
              <v-btn
                depressed
                rounded
                class="button-text tway-violet white--text roboto elevation-3"
                :disabled="!step2validation"
                @click="updateProfile(3)"
              >
                Siguiente
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-stepper-content>
      <!-- PASO 3 -->
      <v-stepper-content step="3">
        <v-form ref="formStep3" v-model="formStep3">
          <div class="stepper-item mb-5">
            <label>Cuéntanos acerca de tu empresa</label>
            <v-textarea
              v-model="company.contributionValue"
              rows="3"
              no-resize
              placeholder="Breve descripción sobre tu producto o servicio que ofreces"
              hide-details
              class="roboto"
            />
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-5">
            <label>Tamaño</label>
            <v-tooltip bottom class="tooltip">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="ml-auto icono"> mdi-information-outline </v-icon>
              </template>
              <span class="sii roboto">El tamaño está definido según el rango de facturación de tu empresa.</span>
            </v-tooltip>
            <v-select
              class="subtitle-2 roboto"
              v-model="company.size"
              :items="companySizes"
              :item-text="(companySizes) => `${companySizes.name} ${companySizes.salesUfRange}`"
              :rules="sizeSet ? [] : [requiredObject('Tamaño de la empresa')]"
              :disabled="sizeSet"
              placeholder="Selecciona un tamaño acorde a tu empresa"
              return-object
            />
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-5">
            <label>Cantidad de trabajadores</label>
            <v-text-field
              v-model="company.workers"
              class="roboto"
              :rules="[validWorkers()]"
              :disabled="workersSet"
              placeholder="Ingresa la cantidad total de trabajadores"
            />
          </div>
          <div class="separator mb-6" />

          <div class="stepper-item mb-5">
            <label>Sitio Web</label>
            <v-text-field
              class="subtitle-2 roboto"
              v-model="company.web"
              placeholder="Ingresa el sitio web de tu empresa"
            />
          </div>
          <div class="separator mb-6" />
          <div class="stepper-item mb-5">
            <label>Perfil Linkedin</label>
            <v-text-field
              class="subtitle-2 roboto"
              v-model="company.linkedIn"
              placeholder="Ingresa el link del perfil de LinkedIn de tu empresa"
            />
          </div>
          <div class="separator mb-6" />

          <v-row class="pa-0 ma-0 mb-5" justify="center" align="center">
            <v-col class="pa-0 ma-0" cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
              <v-btn outlined color="deep-purple accent-4" class="button-text" rounded @click="currentStep = 1">
                Atrás
              </v-btn>
            </v-col>
            <v-col class="pa-0 ma-0" cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
              <v-btn
                depressed
                rounded
                class="button-text tway-violet white--text"
                :disabled="!formStep3"
                @click="updateProfile(4)"
              >
                Siguiente
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-stepper-content>
      <!-- PASO 4 -->
      <v-stepper-content step="4">
        <v-row class="px-12">
          <v-col cols="6" v-if="businessAreaValue != ''">
            <div class="stepper-item mb-5">
              <div class="h6">Área de trabajo</div>
              <div class="subtitle-2 roboto" style="color: #999999">
                {{ businessAreaValue }}
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="separator mb-6" v-if="company.dimensions.lenght != 0" />
        <div class="stepper-item mb-5" v-if="company.dimensions.lenght != 0">
          <div class="h6">Impacto en Transformación Digital</div>
          <v-row>
            <v-col cols="10">
              <div>
                <v-tooltip
                  class="d-inline mr-2"
                  v-for="(sector, index) in flag0 ? company.dimensions : company.dimensions.slice(0, 3)"
                  :key="index"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on" color="tway-gray mb-2">
                      <span class="roboto">{{ sector.name }}</span>
                    </v-chip>
                  </template>
                  <span>{{ sector.name }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="2">
              <v-btn
                id="expand_button"
                @click="
                  () => {
                    flag0 = !flag0;
                  }
                "
                value="expand"
                icon
                color="tway-violet"
              >
                <v-icon v-if="flag0"> expand_less </v-icon>
                <v-icon v-if="!flag0"> expand_more </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="separator mb-6" v-if="company.services.lenght != 0" />
        <div class="stepper-item mb-5" v-if="company.services.lenght != 0">
          <div class="h6">Productos y Servicios</div>
          <v-row>
            <v-col cols="10">
              <div>
                <v-tooltip
                  class="d-inline mr-2"
                  v-for="(service, index) in flag1 ? company.services : company.services.slice(0, 3)"
                  :key="index"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on" color="tway-gray mb-2">
                      <span class="roboto">{{ service.name }}</span>
                    </v-chip>
                  </template>
                  <span>{{ service.name }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="2">
              <v-btn
                id="expand_button"
                @click="
                  () => {
                    flag1 = !flag1;
                  }
                "
                value="expand"
                icon
                color="tway-violet"
              >
                <v-icon v-if="flag1"> expand_less </v-icon>
                <v-icon v-if="!flag1"> expand_more </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="separator mb-6" v-if="company.industrialSectors.lenght != 0" />
        <div class="stepper-item mb-5" v-if="company.industrialSectors.lenght != 0">
          <div class="h6">Experiencia en</div>
          <v-row>
            <v-col cols="10">
              <div>
                <v-tooltip
                  class="d-inline mr-2"
                  v-for="(sector, index) in flag2 ? company.industrialSectors : company.industrialSectors.slice(0, 3)"
                  :key="index"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on" color="tway-gray mb-2">
                      <span class="roboto">{{ sector.name }}</span>
                    </v-chip>
                  </template>
                  <span>{{ sector.name }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="2">
              <v-btn
                id="expand_button2"
                @click="
                  () => {
                    flag2 = !flag2;
                  }
                "
                value="expand"
                icon
                color="tway-violet"
              >
                <v-icon v-if="flag2"> expand_less </v-icon>
                <v-icon v-if="!flag2"> expand_more </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="company.contributionValue != ''" class="separator mb-6" />
        <div v-if="company.contributionValue != ''" class="stepper-item mb-5">
          <div class="h6">Acerca de la Empresa</div>
          <div class="subtitle-2 roboto" style="color: #999999">
            {{ company.contributionValue }}
          </div>
        </div>
        <div v-if="company.trajectory || company.size" class="separator mb-6" />
        <v-row class="stepper-item mb-3">
          <v-col v-if="company.trajectory != ''">
            <div class="h6">Inicio Actividades</div>
            <div>
              <a class="subtitle-2 roboto" style="color: #999999">{{ company.trajectory }}</a>
            </div>
          </v-col>
          <v-col v-if="company.size != ''">
            <div class="h6">Tamaño</div>
            <div>
              <a class="subtitle-2 roboto" style="color: #999999" v-if="company.size"
                >{{ company.size.name }} {{ company.size.salesUfRange }}</a
              >
            </div>
          </v-col>
        </v-row>

        <div v-if="company.web || company.linkedIn" class="separator mb-6" />
        <v-row v-if="company.web != '' || company.linkedIn != ''" class="stepper-item mb-3">
          <v-col cols="6" v-if="company.web != ''">
            <div>
              <div class="h6">Sitio Web</div>
              <div>
                <a
                  target="_blank"
                  class="subtitle-2 roboto"
                  style="color: #999999"
                  v-if="company.web !== ''"
                  :href="'http://' + company.web"
                  >{{ company.web }}</a
                >
              </div>
            </div>
          </v-col>
          <v-col cols="6" v-if="company.linkedIn">
            <div>
              <div class="h6">Perfil Linkedin</div>
              <div>
                <a
                  class="subtitle-2 roboto"
                  style="color: #999999"
                  target="_blank"
                  v-if="company.linkedIn !== ''"
                  :href="'http://' + company.linkedIn"
                  >{{ company.linkedIn }}</a
                >
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="separator mb-6" />

        <v-row :class="mobile ? '' : 'ml-9'" class="pa-0 ma-0 mb-5" justify="center">
          <v-col class="pa-0 ma-0" cols="3" xs="2" sm="2" md="2" lg="2" xl="2">
            <v-btn outlined color="deep-purple accent-4" class="button-text" rounded @click="currentStep = 2">
              Atrás
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0" cols="6" xs="6" sm="6" md="4" lg="4" xl="4">
            <v-btn depressed rounded class="button-text tway-violet white--text" @click="confirmProfile()">
              Confirmar mi perfil
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import companyService from "@/services/companyService";
import currentUserService from "@/services/currentUserService";
import Choice from "@/components/elements/Choice.vue";
import ValidatorsService from "@/services/validatorsService";

export default {
  name: "SpecialistRegistration",

  components: {
    choice: Choice,
  },

  props: {
    companyData: {
      type: Object,
      default: () => {},
      required: true,
    },
    userData: {
      type: Object,
      default: () => {},
      required: true,
    },
    companySizes: {
      type: Array,
      default: () => [],
      required: true,
    },
    companySectors: {
      type: Array,
      default: () => [],
      required: true,
    },
    companyBusinessAreas: {
      type: Array,
      default: () => [],
      required: true,
    },
    companyWorkplaceSenioritys: {
      type: Array,
      default: () => [],
      required: true,
    },
    companyCategories: {
      type: Array,
      default: () => [],
      required: true,
    },
    companyDimensions: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      formStep1: false,
      formStep2: false,
      formStep3: false,
      businessAreaValue: null,
      company: { services: [] },
      workplaceSeniority: null,
      businessArea: null,
      currentUser: {},
      currentStep: 1,
      button: false,
      flag0: false,
      flag1: false,
      flag2: false,
      categories: [],
      dimensions: [],
      businessAreaSet: false,
      workplaceSenioritySet: false,
      nameSet: false,
      yearSet: false,
      sizeSet: false,
      sectorSet: false,
      workersSet: false,
      newCompany: false,
      checkboxServices: false,
      checkboxSectors: false,
      otherServiceSet: false,
    };
  },

  created() {
    if (this.userData.businessArea) {
      this.businessAreaSet = true;
      this.businessArea = this.userData.businessArea;
      this.setBusinessAreaValue();
    }
    if (this.userData.workplaceSeniority) {
      this.workplaceSenioritySet = true;
      this.workplaceSeniority = this.userData.workplaceSeniority;
    }
    this.company = this._.assign(this.company, this.companyData);
    if (this.company.trajectory && this.company.checked) {
      this.yearSet = true;
    }
    if (this.company.size != null) {
      this.sizeSet = true;
    }
    if (this.company.otherService != null) {
      this.checkboxServices = true;
      this.otherServiceSet = true;
    }
    if (this.company.industrialSectors.length > 0 && this.company.checked) {
      this.company.industrialSectors = this.company.industrialSectors.map((e) => e.name);
      this.sectorSet = true;
    }
    if (this.company.dimensions.length > 0) {
      this.company.dimensions = this.company.dimensions.map((e) => {
        return e;
      });
    }
    if (this.company.name != "") {
      this.nameSet = true;
    }
    if (!this.company.checked) {
      this.newCompany = true;
    } else {
      this.newCompany = false;
    }
  },
  methods: {
    confirmProfile() {
      const promo = localStorage.getItem("promo");
      if (promo) {
        this.$emit("planModal", true);
      } else {
        this.$router.push({ name: "home" });
      }
    },
    async updateProfile(step) {
      if (this.currentStep === 1) {
        await currentUserService.currentUserData().then((data) => {
          this.currentUser = data;
        });
        let data = this.currentUser;
        data.metadata = {
          ...data.metadata,
          workplaceSeniority: this.workplaceSeniority,
          businessArea: this.businessArea,
        };
        await currentUserService
          .updateUserData(data)
          .then(() => (this.currentStep = step))
          .catch((err) => this.$log.error(err));
      }
      if (this.currentStep === 2 || this.currentStep === 3) {
        this.currentStep = step;
      }
      if (this.currentStep === 4) {
        let data1 = {
          ...this.company,
          industrialSectors: this.company.industrialSectors.map((e) => e.id),
          dimensions: this.company.dimensions.map((e) => e.id),
        };
        companyService
          .updateCompanyProfile(data1)
          .then(() => (this.currentStep = step))
          .catch((err) => this.$log.error(err));
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    textRange() {
      return this.company.size.name + " " + this.company.size.salesUfRange;
    },
    validYear: () => ValidatorsService.validYear(),
    validWorkers: () => ValidatorsService.validWorkers(),
    required: (propertyType) => ValidatorsService.required(propertyType),
    requiredObject: (propertyType) => ValidatorsService.requiredObject(propertyType),
    setBusinessAreaValue() {
      try {
        let value = this.companyBusinessAreas.find((element) => element.code == this.businessArea);
        this.businessAreaValue = value.name;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    businessArea() {
      this.setBusinessAreaValue();
    },
    currentStep() {
      if (this.currentStep > 1) {
        this.$emit("hide", true);
      } else {
        this.$emit("hide", false);
      }
    },
    checkboxSectors() {
      if (this.checkboxSectors) {
        this.company.industrialSectors = [];
      }
    },
    checkboxServices() {
      if (this.checkboxServices) {
        this.$refs.choice.clean();
        this.company.services = [];
      }
    },
    "company.industrialSectors"() {
      if (this.company.industrialSectors.length > 0) {
        this.checkboxSectors = false;
      }
    },
    "company.services"() {
      if (this.company.services.length > 0) {
        this.checkboxServices = false;
      }
    },
  },
  computed: {
    step2validation() {
      if (this.formStep2 && (this.company.services.length > 0 || this.checkboxServices)) {
        return true;
      }
      return false;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
<style lang="scss" scoped>
.separator {
  border: 1px solid #f1f1f1;
  width: 100%;
}
.select-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: #cccccc;
}
.icono {
  width: 38px;
  color: #4a148c;
  top: -2px;
  font-size: 18px !important;
}
.sii {
  color: white;
}
.tooltip {
  color: #4a148c;
}
</style>
